import { graphql, PageProps } from 'gatsby'
import React, { useEffect, useState } from 'react'
import AllCardWithoutLimit from '../components/allCards/allcardwithoutlimit'
import Layout from '../layouts/layout'

type Categorie = {
  categories_id : {id:string}
}

type DataProps = {
    directus: {
      news: [
        content: any,
        id: string,
        image: {},
        date_published: string,
        categories: [Categorie],
        title: string
      ]
    }
  };
  

const Actualite = ({ data }: PageProps<DataProps>) => {
  const [dataActualite, setDataActualite] = useState<any[]>([])
  const idCat: string = data.directus.news[0].categories[0].categories_id.id

  useEffect (() => {
    setDataActualite(data.directus.news)
  },[])
  return (
    <Layout>
        <AllCardWithoutLimit aData={dataActualite} idCat={idCat}/>
    </Layout>
  )
}

export default Actualite

export const query = graphql`
    query directUSListActualite {
      directus {
        news(filter: {categories: {categories_id: {id: {_eq: "2"}}}, status: {_eq: "published"}} sort: "-date_published") {
          id
          content
          image {
            id
            filename_download
            imageFile {
              publicURL
            }
          }
          date_published
          categories {
            categories_id {
              id
              name
            }
          }
          title
        }
      }
    }
`

